import { RemixBrowser } from '@remix-run/react'
import posthog from 'posthog-js'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

function PosthogInit() {
	useEffect(() => {
		posthog.init(ENV.POSTHOG_PROJECT, {
			api_host: 'https://us.i.posthog.com',
			person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
		})
	}, [])

	return null
}

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
			<PosthogInit />
		</StrictMode>,
	)
})
